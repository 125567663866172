import React, {useState} from 'react';
import {StaticImage} from "gatsby-plugin-image";
import {Trans} from "gatsby-plugin-react-i18next";
import Accordion from '../../../ui/legacy/Accordion/Accordion'
import DigiSymbol from '../../../ui/legacy/DigiSymbols/DigiSymbol'
import {jumpAccordion} from "../../../../utils/utils";

let datasheetsRFID = [
  {
    "title": "Orbit | Basic & Advanced | RFID | Deadbolt | Shared or Assigned | Key Insert (12MM)",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/545C5MhGF2lW9Nc59f0YbL/aff63024f4dbd6695dc857838b41b11e/DS-D6O-D-01-DEN.pdf",
      "fileName": "DS-D6O-D-01-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Orbit | Basic & Advanced | RFID | Deadbolt | Shared or Assigned | Key Insert (15-19MM)",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/5hMBqbPq94cNhaP9H6fSdV/fc4357ad23b27716965eec7d3c74656a/DS-D6O-D-02-DEN.pdf",
      "fileName": "DS-D6O-D-02-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Orbit | Basic & Advanced | RFID | Deadlatch | Assigned | Key Insert (12MM)",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/1kRzZj5ALQB1nHPTqzZ3rq/4eb54a5da3a3c881e6d8a683f663420d/DS-D6O-P-01-DEN.pdf",
      "fileName": "DS-D6O-P-01-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Orbit | Basic & Advanced | RFID | Deadlatch | Assigned | Key Insert (15-19MM)",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/2KZ9G4CoxANciHCfcX1Gcz/819493226ed27ced52d938b5bee26278/DS-D6O-P-02-DEN.pdf",
      "fileName": "DS-D6O-P-02-DEN.pdf",
      "contentType": "application/pdf"
    }
  }
];
let installGuidesRFID = [
  {
    "title": "Orbit | Basic & Advanced | RFID | Key Bypass",
    "file": {
      "url": "https://downloads.ctfassets.net/q5vaqi9g69zw/72AebBJOGK1qNazeddGDTl/13e80fdb8b9f29ae684901deb716b9a2/IG-D6OX-KEY-BYPASS-DEN.pdf",
      "fileName": "IG-D6OX-KEY-BYPASS-DEN.pdf",
      "contentType": "application/pdf"
    }
  }
];
let productGuidesRFID = [
  {
    "title": "Orbit | Basic & Advanced | RFID | Shared",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/5EJJoj8mZf2ixev1fHRdTX/7f625c35d3d7004f61a444bee7c32c81/PG-D6OX-D-DEN.pdf",
      "fileName": "PG-D6OX-D-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Orbit | Basic & Advanced | RFID | Assigned",
    "file": {
      "url": "https://downloads.ctfassets.net/q5vaqi9g69zw/1R3UGwHozC8wh8D9Zsyhiy/2c6588a3a534898dd7b3e4da5a162bb1/PG-D6OX-P-DEN.pdf",
      "fileName": "PG-D6OX-P-DEN.pdf",
      "contentType": "application/pdf"
    }
  }
];

const Tab = (props) => {
  const [toggleState, setToggleState] = useState(1);
  const toggleTab = (index) => {
    setToggleState(index);
  };

  const getActiveClass = (index, className) => {
    return toggleState === index ? className : '';
  };

  return (
      <div className={'tab-container'}>
        <ul className="tab-list unstyled-list">
          <li
              className={getActiveClass(1, 'active')}
              onClick={() => toggleTab(1)}
          >
            Orbit <Trans>rfid</Trans>
          </li>
        </ul>
        <div className="tab-content-container">
          <div className={"tab-content "+getActiveClass(1, 'active')}>
            <div className="front-rear">
              <StaticImage
                  src={'../../../../images/locks/DL22-net-orbit-600x497.png'}
                  loading={'lazy'}
                  width={1200}
                  height={889}
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="Aspire Smart Lock"
              />
            </div>
            <div className="lists">
              {/*DOCUMENTS*/}
              <div label="Document Library">
                <Accordion clsName={'next-support'}>
                  <div label="Data Sheets">
                    <div className="step-images">
                      <ul>
                        {
                          datasheetsRFID.map((doc, index) => (
                                  <li key={index}>
                                    <li key={index}>
                                      <a href={doc.file.url} target={'_blank'} rel={'noreferrer'}>{doc.title}</a>
                                    </li>
                                  </li>
                              )
                          )
                        }
                      </ul>
                    </div>
                  </div>
                  <div label="Install Guides">
                    <div className="step-images">
                      <ul>
                        {
                          installGuidesRFID.map((doc, index) => (
                                  <li key={index}>
                                    <a href={doc.file.url} target={'_blank'} rel={'noreferrer'}>{doc.title}</a>
                                  </li>
                              )
                          )
                        }
                      </ul>
                    </div>
                  </div>
                  <div label="Product Guides">
                    <div className="step-images">
                      <ul>
                        {
                          productGuidesRFID.map((doc, index) => (
                                  <li key={index}>
                                    <a href={doc.file.url} target={'_blank'} rel={'noreferrer'}>{doc.title}</a>
                                  </li>
                              )
                          )
                        }
                      </ul>
                    </div>
                  </div>
                </Accordion>
              </div>
            </div>
          </div>
        </div>

      </div>
  );
};

export default Tab;